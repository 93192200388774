import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles'; // Ensure you have Vuetify styles imported
import './styles.css'; // Import the global CSS file
import router from './router'; // Ensure you have a router
import VueGtag from 'vue-gtag-next';
import { createMetaManager } from 'vue-meta';

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(createMetaManager());

app.use(VueGtag, {
  property: {
    id: 'G-MDRDZ6NSRM' // Replace with your Google Analytics ID
  }
}, router);

app.mount('#app');