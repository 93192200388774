<template>
  <v-card class="info-card" @click="toggleDescription">
    <v-card-title class="title">
      <v-icon left>mdi-home</v-icon>
      Property Description
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12" md="8">
          <p v-if="isTruncated" class="description-text">
            {{ truncatedText }} <br>  <span class="show-more">show more</span>
          </p>
          <p v-else class="description-text">
            {{ fullText }} <span class="show-more">show less</span>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PropertyDescription',
  data() {
    return {
      isTruncated: true,
      fullText: `
        Discover the ultimate oceanfront retreat with breathtaking views from the balcony, living room, and master bedroom suite of this charming condo.
        Step into a modern kitchen equipped with updated appliances and all the essentials you need. Unwind in comfortable recliner seating and enjoy the newly renovated bathrooms for a touch of luxury.
        Located in beautiful Ponce Inlet, this condo offers easy access to waterfront dining, parasailing adventures, sunset cruises, and the Marine Science Center. Don’t miss the opportunity to visit Florida’s tallest lighthouse for a memorable experience!
      `
    };
  },
  computed: {
    truncatedText() {
      const words = this.fullText.split(' ');
      return words.slice(0, 36).join(' ');
    }
  },
  methods: {
    toggleDescription() {
      this.isTruncated = !this.isTruncated;
    }
  }
}
</script>

<style scoped>
.info-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  cursor: pointer; /* Add cursor pointer to indicate clickability */
}

.description-image {
  border-radius: 10px;
  margin-bottom: 16px;
}

.title {
  color: #FF5A5F; /* Primary color */
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}

.description-text {
  color: #484848; /* Dark grey for text similar to Airbnb's style */
  line-height: 1.6;
  margin-bottom: 16px;
}

.show-more {
  color: #FF5A5F; /* Primary color */
  font-weight: bold;
}

.v-icon {
  vertical-align: middle;
  margin-right: 8px;
}
</style>