<template>
  <v-app>
    <home-view />
  </v-app>
</template>

<script>
import HomeView from '@/views/HomeView.vue';

export default {
  name: 'App',
  components: {
    HomeView
  }
}
</script>