<template>
  <v-card class="info-card">
    <v-card-title class="title">
      <v-icon left>mdi-clipboard-list-outline</v-icon>
      Amenities
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-icon left>mdi-checkin</v-icon> Contact-Free Check-In<br>
          <v-icon left>mdi-bed</v-icon> 2 Bedrooms / 3 beds<br>
          <v-icon left>mdi-shower</v-icon> 2 Bathrooms<br>
          <v-icon left>mdi-wifi</v-icon> Free Fast Wi-Fi<br>
          <v-icon left>mdi-snowflake</v-icon> Air Conditioning<br>
          <v-icon left>mdi-silverware-fork-knife</v-icon> Fully equipped kitchen<br>
          <v-icon left>mdi-tshirt-crew-outline</v-icon> Iron<br>
          <v-icon left>mdi-microwave</v-icon> Microwave<br>
          <v-icon left>mdi-stove</v-icon> Oven<br>
        </v-col>
        <v-col cols="12" sm="6">
          <v-icon left>mdi-beach</v-icon> Beachfront<br>
          
          <v-icon left>mdi-pool</v-icon> Pool<br>

          <v-icon left>mdi-car</v-icon> Free parking on premises<br>
          
          <v-icon left>mdi-sofa</v-icon> Outdoor furniture<br>
          <v-icon left>mdi-grill-outline</v-icon> BBQ grill<br>

          
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PropertyAmenities'
}
</script>