export default [
    require('@/assets/balcony1-web.jpg'),
    require('@/assets/balcony2-web.jpg'),
    require('@/assets/balcony3-web.jpg'),

    require('@/assets/SouthPoint_Beach4.jpeg'),
    require('@/assets/sunrise1-web.jpg'),
    require('@/assets/sunrise3-web.jpg'),

    require('@/assets/kitchen1-web.jpg'),
    require('@/assets/kitchen2-web.jpg'),
    require('@/assets/kitchen3-web.jpg'),
    require('@/assets/kitchen4-web.jpg'),
    require('@/assets/kitchen5-web.jpg'),
    require('@/assets/kitchen6-web.jpg'),

    require('@/assets/livingroom1-web.jpg'),
    require('@/assets/livingroom2-web.jpg'),

    require('@/assets/diningroom1-web.jpg'),

    require('@/assets/masterbedroom1-web.jpg'),
    require('@/assets/masterbedroom2-web.jpg'),
    require('@/assets/masterbedroom3-web.jpg'),

    require('@/assets/masterbath1-web.jpg'),
    require('@/assets/masterbath2-web.jpg'),

    require('@/assets/secondbedroom1-web.jpg'),
    require('@/assets/secondbedroom2-web.jpg'),
      
    require('@/assets/bird-web.jpg'),

    require('@/assets/laundry1-web.jpg'),

    require('@/assets/pool1-web.jpg'),
    require('@/assets/pool2-web.jpg'),

    require('@/assets/storage1-web.jpg')

]