<template>
  <v-card class="info-card">
    <v-card-title class="title">
      <v-icon left>mdi-map-marker-multiple</v-icon>
      Nearby Attractions
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-icon left>mdi-food</v-icon> Restaurants<br>
          <v-icon left>mdi-beach</v-icon> Beaches<br>
          <v-icon left>mdi-lighthouse</v-icon> Ponce Inlet Lighthouse<br>
        </v-col>
        <v-col cols="12" sm="6">
          <v-icon left>mdi-fish</v-icon> Marine Science Center<br>
          <v-icon left>mdi-ferry</v-icon> Sunset Cruises<br>
          <v-icon left>mdi-hook</v-icon> Fishing and Fishing Charters<br>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NearbyAttractions'
}
</script>