<template>
  <div class="carousel-container">
    <v-carousel
      v-model="currentSlide"
      height="500px"
      hide-delimiters
      :cycle="cycle"
      class="rounded-carousel"
      hide-delimiter-background
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="i"
        class="carousel-item no-focus-outline"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <img :src="image" alt="" class="carousel-img" />
      </v-carousel-item>
    </v-carousel>
    <div class="slide-counter">{{ currentSlide + 1 }}/{{ images.length }}</div>
    <v-btn
      v-if="isDesktop && showButton"
      @click="showAllPhotos"
      class="show-all-photos-btn"
      color="grey"
      outlined
    >
      <v-icon left>mdi-menu</v-icon>
      Show All Photos
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ImageCarousel',
  props: {
    images: {
      type: Array,
      required: true
    },
    showButton: {
      type: Boolean,
      default: true
    },
    cycle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentSlide: 0,
      isDesktop: window.innerWidth >= 1024
    };
  },
  methods: {
    showAllPhotos() {
      this.$emit('show-all-photos');
    },
    handleResize() {
      this.isDesktop = window.innerWidth >= 1024;
    }
  },
  watch: {
    currentSlide(newSlide) {
      if (this.$gtag) {
        this.$gtag.event('slide_change', {
          event_category: 'Carousel',
          event_label: `Slide ${newSlide + 1} of ${this.images.length}`,
          value: newSlide + 1
        });
      } else {
        console.log('Google Analytics is not initialized');
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.carousel-container {
  position: relative;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images cover the space without distorting */
  border-radius: inherit; /* Apply border-radius from the parent */
}

.rounded-carousel {
  border-radius: 15px; /* Adjust this value to your preference */
  overflow: hidden; /* Ensure content does not overflow the rounded corners */
}

.v-carousel__slides {
  border-radius: inherit; /* Ensure slides also have rounded corners */
}

.carousel-item {
  outline: none; /* Remove focus outline */
}

.no-focus-outline:focus {
  outline: none !important;
}

.slide-counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.show-all-photos-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: grey;
}
</style>