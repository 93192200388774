<template>
  <v-container fluid>
    <!-- Property Title -->
    <v-row justify="center" class="my-4">
      <h1 class="property-title">
        <span class="script-font">Beachside Bliss:</span> Ponce Inlet Oceanfront Condo
      </h1>
    </v-row>

    <!-- Image Carousel -->
    <image-carousel :images="currentImages" :cycle="!dialog" @show-all-photos="trackShowAllPhotos" />

    <!-- Image Carousel Modal -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">All Photos Gallery</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <image-carousel :images="allImages" :showButton="false" :cycle="true" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Search Toolbar (conditionally rendered) -->
    <v-row justify="center" class="my-4" v-if="showToolbar">
      <v-toolbar class="search-toolbar" dense flat>
        <v-spacer></v-spacer>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Arrival Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-text-field
          v-model.number="guests"
          label="Number of Guests"
          prepend-icon="mdi-account"
          type="number"
          min="1"
        ></v-text-field>
        <v-btn color="primary" @click="searchAvailability">Search</v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-row>

    <!-- Call to Action -->
    <v-row justify="center" class="my-4">
      <v-btn
        color="primary"
        dark
        large
        href="https://www.airbnb.com/rooms/1145893196421934972"
        target="_blank"
        @click="trackBookYourStay"
      >
        Book Your Stay
      </v-btn>
    </v-row>

    <!-- Property Information Sections -->
    <v-row>
      <v-col cols="12" md="6">
        <property-description @expand-section="trackExpandPropertyDescription" />
      </v-col>
      <v-col cols="12" md="6">
        <property-amenities />
      </v-col>
      <v-col cols="12">
        <nearby-attractions />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import ImageCarousel from '@/components/ImageCarousel.vue';
import PropertyDescription from '@/components/PropertyDescription.vue';
import PropertyAmenities from '@/components/PropertyAmenities.vue';
import NearbyAttractions from '@/components/NearbyAttractions.vue';

import DesktopImages from '@/plugins/desktopImages.js';
import MobileImages from '@/plugins/mobileImages.js';

export default {
  name: 'HomeView',

  metaInfo: {
    title: 'Beachside Bliss: Ponce Inlet Oceanfront Condo',
    meta: [
      { name: 'description', content: 'Discover the ultimate oceanfront retreat with breathtaking views and luxurious amenities.' },
      { name: 'keywords', content: 'beachside, oceanfront, condo, Ponce Inlet, rental, vacation' },
      // Open Graph Meta Tags
      { property: 'og:title', content: 'Beachside Bliss: Ponce Inlet Oceanfront Condo' },
      { property: 'og:description', content: 'Discover the ultimate oceanfront retreat with breathtaking views and luxurious amenities.' },
      { property: 'og:image', content: 'https://a0.muscache.com/im/pictures/miso/Hosting-1145893196421934972/original/1924164c-3f03-4eb9-b748-f7ead5d7afd8.jpeg?im_w=1200' },
      { property: 'og:url', content: window.location.href },
      { property: 'og:type', content: 'website' },
      // Twitter Card Meta Tags
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Beachside Bliss: Ponce Inlet Oceanfront Condo' },
      { name: 'twitter:description', content: 'Discover the ultimate oceanfront retreat with breathtaking views and luxurious amenities.' },
      { name: 'twitter:image', content: 'https://a0.muscache.com/im/pictures/miso/Hosting-1145893196421934972/original/1924164c-3f03-4eb9-b748-f7ead5d7afd8.jpeg?im_w=1200' },
    ],
  },
  
  components: {
    ImageCarousel,
    PropertyDescription,
    PropertyAmenities,
    NearbyAttractions,
  },
  setup() {
    const { proxy } = getCurrentInstance(); // Access the global properties using getCurrentInstance

    const desktopImages = DesktopImages;
    const mobileImages = MobileImages;
    const allImages = MobileImages;

    const menu = ref(false);
    const date = ref('');
    const guests = ref(1);
    const showToolbar = ref(false);
    const currentImages = ref(desktopImages);
    const dialog = ref(false);

    const updateImages = () => {
      currentImages.value = window.innerWidth < 1024 ? mobileImages : desktopImages;
    };

    onMounted(() => {
      updateImages();
      window.addEventListener('resize', updateImages);

      // Track page view when component is mounted
      if (proxy.$gtag) {
        proxy.$gtag.pageview({
          page_path: window.location.pathname,
          page_title: 'HomeView',
          page_location: window.location.href,
        });
      } else {
        console.log('Google Analytics is not initialized');
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateImages);
    });

    const showModal = () => {
      dialog.value = true;
    };

    const trackShowAllPhotos = () => {
      if (proxy.$gtag) {
        proxy.$gtag.event('show_all_photos', {
          event_category: 'Interaction',
          event_label: 'Show All Photos Button',
        });
      }
      showModal();
    };

    const trackBookYourStay = () => {
      if (proxy.$gtag) {
        proxy.$gtag.event('book_your_stay', {
          event_category: 'Interaction',
          event_label: 'Book Your Stay Button',
        });
      }
    };

    const trackExpandPropertyDescription = () => {
      if (proxy.$gtag) {
        proxy.$gtag.event('expand_property_description', {
          event_category: 'Interaction',
          event_label: 'Expand Property Description',
        });
      }
    };

    return {
      menu,
      date,
      guests,
      showToolbar,
      currentImages,
      dialog,
      allImages,
      showModal,
      trackShowAllPhotos,
      trackBookYourStay,
      trackExpandPropertyDescription,
    };
  },
  methods: {
    searchAvailability() {
      alert(`Searching for availability on ${this.date} for ${this.guests} guests.`);
    },
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
}

.search-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.property-title {
  font-family: 'Lora', sans-serif;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
  color: #484848;
}

.script-font {
  font-family: 'Dancing Script', cursive;
  font-size: 2.5rem; /* Increase the font size */
  font-weight: bold;
}
</style>